import type { CurrencyDisplay } from '~/types/currencyDisplay'

/*
 * Return a currency symbol alone like "€"
 */
export function useFormatCurrency() {
  const { $i18n: { locale } } = useNuxtApp()

  return ({
    count = 0,
    currency = { style: 'currency', currencyDisplay: 'symbol', currency: 'EUR' },
  }: { count?: number, currency?: CurrencyDisplay } = {}) => {
    const formatter = new Intl.NumberFormat(locale.value, currency)
    const part = formatter.formatToParts(count).find(({ type }) => type === 'currency')
    return part?.value
  }
}
